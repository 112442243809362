<template>
  <div class="relative text-sm w-full">
    <!-- Label -->
    <div v-if="label || $slots.label" class="mb-1 line-clamp-1 text-gray-700">
      <slot name="label">{{ label }}</slot>
      <span v-if="required" class="text-red-600 font-medium"> * </span>
    </div>

    <!-- Dropdown Trigger -->
    <DropdownMenu v-model="menuOpen">
      <DropdownMenuTrigger
        class="bg-white border border-slate-300/70 w-full min-h-10 px-3 flex items-center justify-between cursor-pointer rounded-br-xl"
        :class="{
          '!border-sky-600': isFocused,
          '!border-red-300': messages.length,
          '!bg-gray-100': disabled,
        }"
        :disabled="disabled"
      >
        <span v-if="selectedLabel" class="truncate">{{ selectedLabel }}</span>
        <span v-else class="text-gray-500">{{ placeholder }}</span>
        <Icon name="keyboard_arrow_down" class="ml-2 text-gray-500" />
      </DropdownMenuTrigger>

      <!-- Dropdown Content -->
      <DropdownMenuContent
        class="w-full border rounded-md shadow-lg bg-white mt-1 relative z-[9999] min-w-60"
      >
        <!-- Search Box -->
        <div v-if="searchBox" class="p-2 border-b">
          <input
            v-model="searchQuery"
            class="w-full p-2 border rounded text-sm outline-none"
            placeholder="Suchbegriff eingeben..."
            @click.stop
          />
        </div>

        <!-- Options List with Checkboxes -->
        <!-- Options List using a Manual Checkmark SVG -->
        <div class="max-h-60 overflow-y-auto">
          <div
            v-for="option in filteredOptions"
            :key="option[keyValue]"
            class="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 cursor-pointer"
            @click.stop="toggleSelect(option)"
          >
            <span class="truncate flex-1">{{ option[keyName] }}</span>

            <!-- Checkmark SVG (only shown if selected) -->
            <svg
              v-if="isSelected(option[keyValue])"
              class="w-5 h-5 text-blue-500"
              fill="none"
              stroke="#005465"
              stroke-width="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
            </svg>
          </div>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>

    <!-- Validation Messages -->
    <div v-if="messages.length" class="text-xs text-left mt-1.5 text-red-600">
      {{ messages[0] }}
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
} from "@/components/ui/dropdown-menu";

const props = defineProps({
  name: { type: String, default: "" },
  label: { type: String, default: "" },
  placeholder: { type: String, default: "Bitte auswählen" },
  required: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  options: { type: Array, default: () => [] },
  keyName: { type: String, default: "text" },
  keyValue: { type: String, default: "value" },
  searchBox: { type: Boolean, default: true },
  modelValue: { required: true },
  multiple: { type: Boolean, default: false },
});

const emit = defineEmits(["update:modelValue"]);

const menuOpen = ref(false);
const searchQuery = ref("");
const messages = ref([]);
const isFocused = ref(false);

// ✅ Handle single & multi-select properly
const selectedValues = computed(() =>
  props.multiple ? (Array.isArray(props.modelValue) ? props.modelValue : []) : [props.modelValue]
);

const filteredOptions = computed(() =>
  props.options.filter((option) =>
    option[props.keyName]?.toLowerCase().includes(searchQuery.value.toLowerCase())
  )
);

const selectedLabel = computed(() => {
  if (props.multiple) {
    return selectedValues.value
      .map((val) => props.options.find((option) => option[props.keyValue] === val)?.[props.keyName])
      .filter(Boolean)
      .join(", ");
  }
  return (
    props.options.find((option) => option[props.keyValue] === props.modelValue)?.[props.keyName] ||
    ""
  );
});

function isSelected(value) {
  return selectedValues.value.includes(value);
}

function toggleSelect(option) {
  if (props.disabled) return;

  let newValues;
  if (props.multiple) {
    newValues = selectedValues.value.includes(option[props.keyValue])
      ? selectedValues.value.filter((val) => val !== option[props.keyValue])
      : [...selectedValues.value, option[props.keyValue]];
  } else {
    newValues = option[props.keyValue];
    menuOpen.value = false; // Close dropdown for single select
  }

  emit("update:modelValue", props.multiple ? newValues : newValues);
  emit("change", newValues); // 🔥 Trigger updates like the original `<select>`
}
</script>
